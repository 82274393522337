import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from "gatsby";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Тестирование в JS" mdxType="SEO" />
    <h1>{`План курса "Тестирование в JS"`}</h1>
    <h2>{`Для чего нужны тесты`}</h2>
    <p>{`Тесты нужны по нескольким причинам:`}</p>
    <ul>
      <li parentName="ul">{`Экономят время и средства. Позволяют не проверять руками работу кода
во время разработки и после`}</li>
      <li parentName="ul">{`Могут являться спецификацией по работе функционала`}</li>
      <li parentName="ul">{`Позволяют быстро обнаружить ошибки при грядущих изменениях кода`}</li>
      <li parentName="ul">{`Имеют возможность выполняться перед деплоем для валидации работы приложения
в определенном окружении`}</li>
    </ul>
    <h2>{`Разновидности тестов`}</h2>
    <ul>
      <li parentName="ul">{`Статическая типизация`}</li>
      <li parentName="ul">{`Unit-тесты`}</li>
      <li parentName="ul">{`Интеграционные тесты`}</li>
      <li parentName="ul">{`Snapshot тестирование`}</li>
      <li parentName="ul">{`End-to-end тесты`}</li>
    </ul>
    <h2>{`Статическая типизация`}</h2>
    <ul>
      <li parentName="ul">{`Как работает. Отлавливает ошибки и опечатки во время сборки приложения`}</li>
      <li parentName="ul">{`Может служить документацией`}</li>
      <li parentName="ul">{`Облегчает понимание кода во время чтения и ускоряет ревью`}</li>
      <li parentName="ul">{`Знакомство с TypeScript`}</li>
    </ul>
    <h2>{`Unit-тесты`}</h2>
    <ul>
      <li parentName="ul">{`Как работают. Проверяют работоспособность модулей в изоляции`}</li>
      <li parentName="ul">{`Могут служить спецификациями функционала`}</li>
      <li parentName="ul">{`Атомарность тестов, параллельной запуск, изоляция`}</li>
      <li parentName="ul">{`Настройка `}<inlineCode parentName="li">{`jest`}</inlineCode>{` в проекте`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`expect`}</inlineCode>{` и другое API`}</li>
      <li parentName="ul">{`Mock функций, модулей и зависимостей`}</li>
    </ul>
    <h2>{`Интеграционные тесты`}</h2>
    <ul>
      <li parentName="ul">{`Как работают. Проверяют работоспособность двух и более модулей вместе`}</li>
      <li parentName="ul">{`Позволяют быстро обнаружить непредвиденные ошибки`}</li>
      <li parentName="ul">{`Описывают возможности интеграции модулей, демонстрируют их API`}</li>
    </ul>
    <h2>{`Snapshot тестирование`}</h2>
    <ul>
      <li parentName="ul">{`Как работает. Сообщает об отличиях текущего снапшота от предыдущего`}</li>
      <li parentName="ul">{`Позволяет быстро обнаружить нежелательные и случайные изменения`}</li>
      <li parentName="ul">{`Перезапись снапшота при намеренных изменениях`}</li>
      <li parentName="ul">{`Комплиментарные тесты с `}<inlineCode parentName="li">{`expect().toMatchSnapshot()`}</inlineCode></li>
    </ul>
    <h2>{`Testing Library`}</h2>
    <p>{`Позволяет тестировать компоненты в изоляции, интеграции и даже e2e
со стороны пользователя.`}</p>
    <ul>
      <li parentName="ul">{`Как работает. Рендерит определенный DOM-элемент и позволяет с ним взаимодействовать
от лица пользователя`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@testing-library/dom`}</inlineCode>{`, `}<inlineCode parentName="li">{`@testing-library/react`}</inlineCode>{` и др.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`render`}</inlineCode>{` возвращает удобное API для взаимодействия с DOM-элементом`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@testing-library/user-event`}</inlineCode>{`. Имлементации действий пользователя`}</li>
      <li parentName="ul">{`Расширение методов `}<inlineCode parentName="li">{`expect`}</inlineCode>{` для большей декларативности и чистоты тестов`}</li>
    </ul>
    <h2>{`End-to-end`}</h2>
    <ul>
      <li parentName="ul">{`Как работает. Имитирует взаимодействие пользователя с приложением в браузере`}</li>
      <li parentName="ul">{`Регрессионное тестирование и его автоматизация`}</li>
      <li parentName="ul">{`Знакомство с Cypress`}</li>
      <li parentName="ul">{`Чаще всего написанием e2e тестов занимаются QA-автоматизаторы`}</li>
    </ul>
    <h2>{`Что тестировать, когда и как?`}</h2>
    <p>{`На бизнес-логику, утилиты и форматтеры стоит писать unit-тесты, чтобы быть
уверенным в том, что они верно работают в изоляции. На взаимодействие ключевого
функционала стоит писать интеграционные тесты, которые отловят, если что-то
будет некорректно работать друг с другом после изменений. Чтобы не проводить
мануально регрессионные тестирования всего приложения целиком, лучше
поддерживать end-to-end тесты. В некоторых случаях большую пользу может принести
snapshot тестирование. Его использование может  защитить модули от нежелательных
изменений.`}</p>
    <p>{`Избирать подход в зависимости от толерантности приложения к наличию возможных ошибок,
количества затрачиваемого времени на ручные проверки написанного кода, количества
коммитов и вовлеченных разработчиков. В конце концов, для некоторых приложений
ручное тестирование может быть даже выгодней.`}</p>
    <p>{`Покрытие кода (Code Coverage) - процент кода, который выполняется при выполнении тестов.
Не нужно добиваться 100% покрытия кода и тестировать проверенный функционал библиотек.
Многие библиотеки имеют 100% покрытие кода тестами, потому что их код исходит от
большего количества разрабочиков. Покрытие тестами позволяет отследить опечатки,
ошибки и нежелательные последствия. Однако покрытие кода тестами не означает,
что тесты написаны без упущений и покрывают всё возможные кейсы использования библиотеки.`}</p>
    <h2>{`Бонус: Методология TDD`}</h2>
    <p>{`Test-Driven-Development (TDD) часто может сэкономить время на ручных проверках
работоспособности кода, позволит определить понятную спецификацию выполнения
задачи. Чем раньше написан тест, тем больше выгоды он принесет.`}</p>
    <Link to="/" mdxType="Link">Вернуться на главную</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      